import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { fetchRecords } from '../../services/airtable';

function Dashboard() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [airtableData, setAirtableData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          // Fetch Airtable data
          const airtableResult = await fetchRecords('DashboardConnection', currentUser);
          setAirtableData(airtableResult);
        } catch (err) {
          console.error("Error fetching data:", err);
          setError("Unable to load data. Please try again later.");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : airtableData ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(airtableData.records[0].manipulated).map((key) => (
                        <TableCell key={key}>{airtableData.customLabels[key] || key}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {airtableData.records.map((record, index) => (
                      <TableRow key={index}>
                        {Object.values(record.manipulated).map((value, cellIndex) => (
                          <TableCell key={cellIndex}>{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Typography>No data available. Please try again later.</Typography>
      )}
    </div>
  );
}

export default Dashboard;
