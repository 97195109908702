import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useRole } from '../contexts/RoleContext';

function AdminRoute({ children }) {
  const { currentUser, loading: authLoading } = useAuth();
  const { isAdministrator, loading: roleLoading } = useRole();

  if (authLoading || roleLoading) {
    return <div>Loading...</div>;
  }

  return currentUser && isAdministrator ? children : <Navigate to="/" />;
}

export default AdminRoute;
