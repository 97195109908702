import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const operators = [
  { value: '=', label: 'equals' },
  { value: '!=', label: 'does not equal' },
  { value: '<', label: 'less than' },
  { value: '<=', label: 'less than or equal to' },
  { value: '>', label: 'greater than' },
  { value: '>=', label: 'greater than or equal to' },
  { value: 'CONTAINS()', label: 'contains' },
];

const userVariables = [
  { value: '{user.contactId}', label: 'Contact ID' },
];

const logicalOperators = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
];

function FilterFormulaBuilder({ initialFormula, onFormulaChange }) {
  const [conditions, setConditions] = useState([
    { field: '', operator: '=', value: '', useUserVariable: false, logicalOperator: 'AND' }
  ]);

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setConditions(newConditions);
  };

  const addCondition = () => {
    setConditions([...conditions, { field: '', operator: '=', value: '', useUserVariable: false, logicalOperator: 'AND' }]);
  };

  const removeCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  const buildFormula = () => {
    const formula = conditions.map((condition, index) => {
      let conditionFormula = '';
      if (condition.operator === 'CONTAINS()') {
        conditionFormula = `CONTAINS({${condition.field}}, "${condition.value}")`;
      } else {
        const value = condition.useUserVariable ? condition.value : `'${condition.value}'`;
        conditionFormula = `{${condition.field}} ${condition.operator} ${value}`;
      }
      return index === 0 ? conditionFormula : `${condition.logicalOperator} ${conditionFormula}`;
    }).join(' ');

    onFormulaChange(formula);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Build Filter Formula
      </Typography>
      {conditions.map((condition, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
          {index > 0 && (
            <FormControl fullWidth>
              <InputLabel>Logical Operator</InputLabel>
              <Select
                value={condition.logicalOperator}
                onChange={(e) => handleConditionChange(index, 'logicalOperator', e.target.value)}
                label="Logical Operator"
              >
                {logicalOperators.map((op) => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            label="Field Name"
            value={condition.field}
            onChange={(e) => handleConditionChange(index, 'field', e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Operator</InputLabel>
            <Select
              value={condition.operator}
              onChange={(e) => handleConditionChange(index, 'operator', e.target.value)}
              label="Operator"
            >
              {operators.map((op) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Value Type</InputLabel>
            <Select
              value={condition.useUserVariable ? 'user' : 'custom'}
              onChange={(e) => handleConditionChange(index, 'useUserVariable', e.target.value === 'user')}
              label="Value Type"
            >
              <MenuItem value="custom">Custom Value</MenuItem>
              <MenuItem value="user">User Variable</MenuItem>
            </Select>
          </FormControl>
          {condition.useUserVariable ? (
            <FormControl fullWidth>
              <InputLabel>User Variable</InputLabel>
              <Select
                value={condition.value}
                onChange={(e) => handleConditionChange(index, 'value', e.target.value)}
                label="User Variable"
              >
                {userVariables.map((variable) => (
                  <MenuItem key={variable.value} value={variable.value}>
                    {variable.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              label="Value"
              value={condition.value}
              onChange={(e) => handleConditionChange(index, 'value', e.target.value)}
              fullWidth
            />
          )}
          {conditions.length > 1 && (
            <IconButton onClick={() => removeCondition(index)} color="error">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button startIcon={<AddIcon />} onClick={addCondition} variant="outlined" sx={{ mb: 2 }}>
        Add Condition
      </Button>
      <Button variant="contained" onClick={buildFormula} fullWidth>
        Build Formula
      </Button>
    </Box>
  );
}

export default FilterFormulaBuilder;
