import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { auth, db, getUserRole, updateUserEmail as updateUserEmailInFirebase } from '../services/firebase';
import { reauthenticateWithCredential, EmailAuthProvider, sendEmailVerification, reload, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../constants/firebaseCollections';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await reload(user);  // Force refresh the user object
        setCurrentUser(user);
        
        // Check if user profile exists, if not create one with default role
        const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          await setDoc(userDocRef, {
            email: user.email,
            role: 'user'
          });
        }
        
        // Migrate existing admin
        if (user.uid === 'M8qsgWJfSohaODGYjWsokrsB3hr1' || user.email === 'aaron.chow@sfcp.org') {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && (!userDoc.data().role || userDoc.data().role !== 'administrator')) {
            await setDoc(userDocRef, { role: 'administrator' }, { merge: true });
          }
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const sendVerificationEmail = async () => {
    if (currentUser) {
      try {
        await reload(currentUser);  // Force refresh the user object
        if (!currentUser.emailVerified) {
          await sendEmailVerification(currentUser);
          return { success: true, message: "Verification email sent. Please check your inbox." };
        } else {
          return { success: true, message: "Your email is already verified." };
        }
      } catch (error) {
        console.error("Error sending verification email:", error);
        return { success: false, error: error.message };
      }
    } else {
      return { success: false, error: "User is not logged in." };
    }
  };

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  const updateUserEmail = async (newEmail, password) => {
    try {
      await reload(currentUser);  // Force refresh the user object
      console.log("Current email verified status:", currentUser.emailVerified);

      if (!currentUser.emailVerified) {
        return { success: false, error: "Please verify your current email before changing it." };
      }

      if (newEmail === currentUser.email) {
        return { success: false, error: "New email is the same as the current email." };
      }

      const credential = EmailAuthProvider.credential(currentUser.email, password);
      await reauthenticateWithCredential(currentUser, credential);

      // Update email in Firebase Authentication and Firestore
      await updateUserEmailInFirebase(currentUser.uid, newEmail);

      // Send verification email to the new email address
      await sendEmailVerification(currentUser);

      console.log("Email update process completed successfully");
      return { success: true, message: "Email updated. Please verify your new email address." };
    } catch (error) {
      console.error("Error updating email:", error);
      if (error.code === "auth/requires-recent-login") {
        return { success: false, error: "This operation is sensitive and requires recent authentication. Please log in again before retrying this request." };
      }
      return { success: false, error: error.message };
    }
  };

  const isAdmin = useCallback(async () => {
    if (currentUser) {
      const role = await getUserRole(currentUser.uid);
      return role === 'administrator';
    }
    return false;
  }, [currentUser]);

  const value = {
    currentUser,
    loading,
    login,
    updateUserEmail,
    sendVerificationEmail,
    isAdmin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
