import { db, auth, analytics, createUserWithEmailAndPassword, sendEmailVerification, updateEmail } from '../config/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, updateDoc, getDoc, setDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { COLLECTIONS } from '../constants/firebaseCollections';

export { db, auth, analytics };

export const setUserRole = async (userId, role) => {
  if (role !== 'user' && role !== 'administrator') {
    throw new Error('Invalid role. Must be either "user" or "administrator".');
  }
  try {
    const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, userId);
    await updateDoc(userDocRef, {
      role: role
    });
    console.log(`Role set to ${role} successfully`);
  } catch (error) {
    console.error('Error setting role:', error);
    throw error;
  }
};

export const getUserRole = async (userId) => {
  try {
    const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const role = userDoc.data().role;
      return role === 'administrator' ? 'administrator' : 'user';
    }
    return 'user';
  } catch (error) {
    console.error('Error getting user role:', error);
    throw error;
  }
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const updateUserEmail = async (userId, newEmail) => {
  try {
    const user = auth.currentUser;
    if (user) {
      await updateEmail(user, newEmail);
      
      // Update email in Firestore
      const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, userId);
      await updateDoc(userDocRef, { email: newEmail });
      
      const userAuthDocRef = doc(db, COLLECTIONS.USERS, userId);
      await updateDoc(userAuthDocRef, { email: newEmail });
      
      console.log('Email updated successfully');
    } else {
      throw new Error('No authenticated user found');
    }
  } catch (error) {
    console.error('Error updating email:', error);
    throw error;
  }
};

export const updateUserContactId = async (userId, contactId) => {
  try {
    const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, userId);
    await updateDoc(userDocRef, { contactId: contactId });
    console.log('Contact ID updated successfully');
  } catch (error) {
    console.error('Error updating Contact ID:', error);
    throw error;
  }
};

export const updateUserAdminStatus = async (userId, isAdmin) => {
  try {
    const userAuthDocRef = doc(db, COLLECTIONS.USERS, userId);
    await updateDoc(userAuthDocRef, { isAdmin: isAdmin });
    console.log('Admin status updated successfully');
  } catch (error) {
    console.error('Error updating admin status:', error);
    throw error;
  }
};

export const createNewUser = async (email, contactId, role, groups) => {
  try {
    // Create user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, 'temporaryPassword');
    const user = userCredential.user;

    // Add user details to Firestore
    const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, user.uid);
    await setDoc(userDocRef, {
      email,
      contactId,
      role,
      groups,
      createdAt: new Date(),
      emailVerified: false
    });

    // Add user to the 'users' collection as well
    const userAuthDocRef = doc(db, COLLECTIONS.USERS, user.uid);
    await setDoc(userAuthDocRef, {
      email,
      isAdmin: role === 'administrator',
      emailVerified: false
    });

    // Send email verification
    await sendEmailVerification(user);

    console.log('New user created successfully');
    return user.uid;
  } catch (error) {
    console.error('Error creating new user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    // Delete user data from Firestore
    const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, userId);
    await deleteDoc(userDocRef);

    const userAuthDocRef = doc(db, COLLECTIONS.USERS, userId);
    await deleteDoc(userAuthDocRef);

    // Call the HTTP function to delete the user from Authentication
    const idToken = await auth.currentUser.getIdToken();
    console.log('Sending delete request for user:', userId);
    const response = await fetch('https://us-central1-student-portal-c0e03.cloudfunctions.net/deleteUsers', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({ uid: userId })
    });

    if (!response.ok) {
      throw new Error(`Failed to delete user: ${response.status} - ${response.statusText}`);
    }

    const responseText = await response.text();
    console.log('Delete user response:', responseText);

    console.log('User deleted successfully from both Firestore and Authentication');
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const usersRef = collection(db, COLLECTIONS.USERS);
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      throw new Error('User not found');
    }

    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();

    if (userData.emailVerified) {
      throw new Error('Email is already verified');
    }

    await sendPasswordResetEmail(auth, email);
    console.log('Verification email sent successfully');
  } catch (error) {
    console.error('Error resending verification email:', error);
    throw error;
  }
};

