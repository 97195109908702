import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from '@mui/material';
import { fetchRecords } from '../../services/airtable';
import { db } from '../../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

const formatFilterFormula = (formula, user) => {
  if (!formula) return 'No filter applied';
  let formattedFormula = formula;
  formattedFormula = formattedFormula.replace(/{user.email}/g, user.email);
  formattedFormula = formattedFormula.replace(/{user.uid}/g, user.uid);
  return formattedFormula;
};

function AirtableConnectionTester() {
  const { currentUser } = useAuth();
  const [connections, setConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState('');
  const [records, setRecords] = useState(null);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchConnections();
  }, []);

  const fetchConnections = async () => {
    const querySnapshot = await getDocs(collection(db, 'airtableConnections'));
    setConnections(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleConnectionChange = (e) => {
    setSelectedConnection(e.target.value);
  };

  const handleFetchRecords = async () => {
    setError('');
    setRecords(null);
    try {
      const fetchedRecords = await fetchRecords(selectedConnection, currentUser);
      setRecords(fetchedRecords);
    } catch (error) {
      setError('Error fetching records: ' + error.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTable = (data) => {
    if (!data || data.records.length === 0) return null;

    return (
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(data.records[0].original).map((key) => (
                <TableCell key={key}>{data.customLabels[key] || key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.records.map((record, index) => (
              <TableRow key={index}>
                {Object.entries(activeTab === 0 ? record.original : record.manipulated).map(([key, value], idx) => (
                  <TableCell key={idx}>{JSON.stringify(value)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Test Airtable Connection
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          select
          label="Select Connection"
          value={selectedConnection}
          onChange={handleConnectionChange}
          fullWidth
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Select a connection</option>
          {connections.map((connection) => (
            <option key={connection.id} value={connection.name}>
              {connection.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Button variant="contained" onClick={handleFetchRecords} disabled={!selectedConnection}>
        Fetch Records
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {selectedConnection && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">
            Applied Filter: {formatFilterFormula(connections.find(c => c.name === selectedConnection)?.filterByFormula, currentUser)}
          </Typography>
        </Box>
      )}
      {records && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="data tabs">
              <Tab label="Original Data" />
              <Tab label="Manipulated Data" />
            </Tabs>
          </Box>
          {renderTable(records)}
        </>
      )}
    </Container>
  );
}

export default AirtableConnectionTester;
