import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Container, Box, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../../services/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { COLLECTIONS } from '../../constants/firebaseCollections';

function UserGroupManager() {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({ name: '', description: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, groupId: null });

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    const querySnapshot = await getDocs(collection(db, COLLECTIONS.USER_GROUPS));
    setGroups(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup(prev => ({ ...prev, [name]: value }));
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    if (newGroup.name.trim()) {
      try {
        await addDoc(collection(db, COLLECTIONS.USER_GROUPS), {
          name: newGroup.name.trim(),
          description: newGroup.description.trim()
        });
        setNewGroup({ name: '', description: '' });
        fetchGroups();
      } catch (error) {
        console.error("Error adding user group:", error);
        alert(`Failed to add user group: ${error.message}. Please check your permissions and try again.`);
      }
    }
  };

  const handleDeleteConfirmation = (groupId) => {
    setDeleteConfirmation({ open: true, groupId });
  };

  const handleDeleteGroup = async () => {
    if (deleteConfirmation.groupId) {
      await deleteDoc(doc(db, COLLECTIONS.USER_GROUPS, deleteConfirmation.groupId));
      setDeleteConfirmation({ open: false, groupId: null });
      fetchGroups();
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Manage User Groups
      </Typography>
      <Box component="form" onSubmit={handleAddGroup} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="groupName"
          label="New Group Name"
          name="name"
          value={newGroup.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="groupDescription"
          label="Group Description"
          name="description"
          value={newGroup.description}
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Add Group
        </Button>
      </Box>
      <List>
        {groups.map((group) => (
          <ListItem
            key={group.id}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirmation(group.id)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={group.name} secondary={group.description} />
          </ListItem>
        ))}
      </List>
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, groupId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation({ open: false, groupId: null })}>Cancel</Button>
          <Button onClick={handleDeleteGroup} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UserGroupManager;
