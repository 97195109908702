import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Typography, Container, Box, ListItem, ListItemText, IconButton, CircularProgress, Paper, FormControl, InputLabel, Select, MenuItem, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import { db } from '../../services/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, setDoc, writeBatch } from 'firebase/firestore';
import { COLLECTIONS } from '../../constants/firebaseCollections';
import { getAuth } from 'firebase/auth';
import CustomReactQuill from './CustomReactQuill';

function PageManager() {
  const [pages, setPages] = useState([]);
  const [newPage, setNewPage] = useState({ title: '', content: '', slug: '', allowedGroups: [], isPublic: false, order: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [editingPage, setEditingPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);

  const onDragEnd = useCallback(async (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(pages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedPages = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setPages(updatedPages);

    // Update the order in Firestore
    const batch = writeBatch(db);
    updatedPages.forEach((page) => {
      const pageRef = doc(db, COLLECTIONS.PAGES, page.id);
      batch.update(pageRef, { order: page.order });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Error updating page orders:', error);
      setError('Failed to update page orders. Please try again.');
    }
  }, [pages]);
  const fetchUserGroups = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, COLLECTIONS.USER_GROUPS));
      setUserGroups(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Error fetching user groups:', error);
      setError('Failed to load user groups. Please try again.');
    }
  }, []);

  const fetchPages = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user || !user.uid) {
        console.error('No user logged in or UID is undefined');
        setError('User authentication error. Please sign out and sign in again.');
        setPages([]);
        return;
      }

      if (!COLLECTIONS.PAGES) {
        console.error('COLLECTIONS.PAGES is undefined');
        setError('Application configuration error. Please contact support.');
        setPages([]);
        return;
      }

      const pagesRef = collection(db, COLLECTIONS.PAGES);
      const querySnapshot = await getDocs(pagesRef);
      let fetchedPages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Ensure the Home, 404, and Profile pages exist
      const defaultPages = [
        {
          slug: 'home',
          title: 'Home',
          content: 'Welcome to the Student Portal',
          order: -3
        },
        {
          slug: '404',
          title: '404 - Page Not Found',
          content: 'Sorry, the page you are looking for does not exist.',
          order: -2
        },
        {
          slug: 'profile',
          title: 'Profile',
          content: 'Your profile information will be displayed here.',
          order: -1
        }
      ];

      for (const defaultPage of defaultPages) {
        let page = fetchedPages.find(p => p.slug === defaultPage.slug);
        if (!page) {
          const pageRef = doc(pagesRef, defaultPage.slug);
          const pageData = {
            ...defaultPage,
            allowedGroups: [],
            isPublic: true
          };
          await setDoc(pageRef, pageData);
          page = { id: defaultPage.slug, ...pageData };
        } else {
          page = { id: page.id, ...page };
        }
        fetchedPages = fetchedPages.filter(p => p.slug !== defaultPage.slug);
        fetchedPages.unshift(page);
      }
      
      // Sort pages by order
      fetchedPages.sort((a, b) => (a.order || 0) - (b.order || 0));
      
      console.log('Fetched pages:', fetchedPages);
      setPages(fetchedPages);
    } catch (error) {
      console.error('Error fetching pages:', error);
      setError(`Failed to load pages: ${error.message}`);
      setPages([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserGroups();
    fetchPages();
  }, [fetchUserGroups, fetchPages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPage(prev => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (content) => {
    setNewPage(prev => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const pageData = {
        title: newPage.title || '',
        content: newPage.content || '',
        slug: newPage.slug || '',
        allowedGroups: newPage.allowedGroups || [],
        isPublic: newPage.isPublic || false,
        order: newPage.order || 0,
      };

      // Remove any empty string or undefined values from pageData
      Object.keys(pageData).forEach(key => {
        if (pageData[key] === '' || pageData[key] === undefined) {
          delete pageData[key];
        }
      });

      console.log('Saving page data:', JSON.stringify(pageData, null, 2));

      if (editingPage) {
        await updateDoc(doc(db, COLLECTIONS.PAGES, editingPage.id), pageData);
      } else {
        await addDoc(collection(db, COLLECTIONS.PAGES), pageData);
      }
      setNewPage({ title: '', content: '', slug: '', allowedGroups: [], isPublic: false, order: 0 });
      setEditingPage(null);
      setIsEditing(false);
      fetchPages();
    } catch (error) {
      console.error('Error adding/updating page:', error);
      setError(`Failed to save page: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Removed renderAirtableData and renderPageContent functions

  const handleDeleteConfirm = (page) => {
    setPageToDelete(page);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (pageToDelete) {
      setLoading(true);
      setError('');
      try {
        await deleteDoc(doc(db, COLLECTIONS.PAGES, pageToDelete.id));
        fetchPages();
        setDeleteConfirmOpen(false);
        setPageToDelete(null);
      } catch (error) {
        console.error('Error deleting page:', error);
        setError('Failed to delete page. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (page) => {
    setEditingPage(page);
    setNewPage({
      title: page.title,
      content: page.content,
      slug: page.slug,
      allowedGroups: page.allowedGroups || [],
      order: page.order || 0,
    });
    setIsEditing(true);
  };

  if (isEditing) {
    return (
      <Container maxWidth="lg">
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <IconButton onClick={() => setIsEditing(false)} sx={{ mr: 2 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" component="h1">
              {editingPage ? 'Edit Page' : 'Add New Page'}
            </Typography>
          </Box>
          {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Page Title"
              name="title"
              value={newPage.title}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="slug"
              label="Page Slug"
              name="slug"
              value={newPage.slug}
              onChange={handleInputChange}
              helperText="This will be used in the URL, e.g., /page/your-slug"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="order"
              label="Page Order"
              name="order"
              type="number"
              value={newPage.order}
              onChange={handleInputChange}
              helperText="Lower numbers will appear first in the sidebar"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="allowed-groups-label">Allowed User Groups</InputLabel>
              <Select
                labelId="allowed-groups-label"
                id="allowedGroups"
                multiple
                value={newPage.allowedGroups || []}
                onChange={(e) => setNewPage(prev => ({ ...prev, allowedGroups: e.target.value }))}
                renderValue={(selected) => selected.join(', ')}
              >
                {userGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    <Checkbox checked={(newPage.allowedGroups || []).indexOf(group.id) > -1} />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Page Content</Typography>
            <CustomReactQuill
              value={newPage.content}
              onChange={handleContentChange}
              style={{ height: '500px', marginBottom: '50px' }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : (editingPage ? 'Update' : 'Add')}
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  console.log('Pages data:', JSON.stringify(pages, null, 2));
  
  const DraggablePageList = () => {
    console.log('Rendering DraggablePageList');
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="pages">
          {(provided) => (
            <Paper {...provided.droppableProps} ref={provided.innerRef}>
              {pages.map((page, index) => {
                const draggableId = page.id;
                console.log(`Rendering Draggable item: ${draggableId}`);
                return (
                  <Draggable key={draggableId} draggableId={draggableId} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        secondaryAction={
                          <>
                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(page)}>
                              <EditIcon />
                            </IconButton>
                            {!['home', '404', 'profile'].includes(page.slug) && (
                              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteConfirm(page)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </>
                        }
                      >
                        <IconButton {...provided.dragHandleProps}>
                          <DragIndicatorIcon />
                        </IconButton>
                        <ListItemText
                          primary={page.title}
                          secondary={`Slug: ${page.slug} | Public: ${page.isPublic ? 'Yes' : 'No'} | Order: ${page.order}`}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Paper>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Manage Pages
      </Typography>
      <Button variant="contained" onClick={() => setIsEditing(true)} sx={{ mb: 2 }}>
        Add New Page
      </Button>
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      {loading ? (
        <CircularProgress />
      ) : (
        <DraggablePageList />
      )}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Page Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to delete the page "{pageToDelete?.title}". 
            This action cannot be undone. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default PageManager;
