import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { testFirebaseConnection } from '../config/firebaseConfig';

function TestPage() {
  const [testResult, setTestResult] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runTest = async () => {
      const result = await testFirebaseConnection();
      setTestResult(result);
      setLoading(false);
    };

    runTest();
  }, []);

  return (
    <Container>
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Firebase Configuration Test
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">
            {testResult
              ? "Firebase connection test passed successfully!"
              : "Firebase connection test failed. Check the console for more details."}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default TestPage;
