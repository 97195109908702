import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import PageIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../../contexts/AuthContext';
import { useRole } from '../../contexts/RoleContext';
import { auth, db } from '../../services/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../../constants/firebaseCollections';

const AdminMenuItems = ({ handleNavigation }) => {
  const { userRole } = useRole();
  
  if (userRole !== 'administrator') {
    return null;
  }

  return (
    <>
      <ListItem button onClick={() => handleNavigation('/admin/airtable')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Airtable Connections" />
      </ListItem>
      <ListItem button onClick={() => handleNavigation('/admin/user-groups')}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary="User Groups" />
      </ListItem>
      <ListItem button onClick={() => handleNavigation('/admin/users')}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Users" />
      </ListItem>
      <ListItem button onClick={() => handleNavigation('/admin/pages')}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Pages" />
      </ListItem>
    </>
  );
};

const drawerWidth = 240;

function Sidebar() {
  const { currentUser } = useAuth();
  const [pages, setPages] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (to) => {
    navigate(to);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    const fetchPages = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const userGroups = userData.groups || [];

            const pagesRef = collection(db, COLLECTIONS.PAGES);
            const pagesQuery = query(pagesRef, where('allowedGroups', 'array-contains-any', userGroups));
            const querySnapshot = await getDocs(pagesQuery);
            
            const fetchedPages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // Sort pages based on the order field
            const sortedPages = fetchedPages.sort((a, b) => (a.order || 0) - (b.order || 0));
            setPages(sortedPages);
          }
        } catch (error) {
          console.error('Error fetching pages:', error);
        }
      } else {
        setPages([]);
      }
    };

    fetchPages();
  }, [currentUser]);

  const handleLogout = () => {
    auth.signOut();
  };

  const drawer = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
        <img src="/logo.png" alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={() => handleNavigation('/home')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {currentUser && (
          <>
            <ListItem button onClick={() => handleNavigation('/dashboard')}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/profile')}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </>
        )}
        {currentUser && <AdminMenuItems handleNavigation={handleNavigation} />}
        {currentUser && pages.map((page) => (
          <ListItem key={page.id} button onClick={() => handleNavigation(`/page/${page.slug}`)}>
            <ListItemIcon>
              <PageIcon />
            </ListItemIcon>
            <ListItemText primary={page.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {!currentUser ? (
          <>
            <ListItem button onClick={() => handleNavigation('/login')}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/register')}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Register" />
            </ListItem>
          </>
        ) : (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, position: 'absolute', top: 10, left: 10, zIndex: 1100 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={isMobile ? handleDrawerToggle : undefined}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default Sidebar;
