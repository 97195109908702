import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateEmail } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqq6GL45s_OIYYZpjVIWt1sC2KiofNrPk",
  authDomain: "student-portal-c0e03.firebaseapp.com",
  projectId: "student-portal-c0e03",
  storageBucket: "student-portal-c0e03.appspot.com",
  messagingSenderId: "296534341719",
  appId: "1:296534341719:web:4ffe5019586bd6c2a84b12",
  measurementId: "G-T3MPRX09MS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, analytics, createUserWithEmailAndPassword, sendEmailVerification, updateEmail };

// Test function to check Firebase configuration
export async function testFirebaseConnection() {
  try {
    // Test Firestore connection
    const testCollection = collection(db, 'test');
    const testDoc = await addDoc(testCollection, { test: true });
    await deleteDoc(testDoc);
    console.log('Firestore connection successful');

    // Test Authentication
    const currentUser = auth.currentUser;
    console.log('Authentication initialized', currentUser ? 'User is signed in' : 'No user signed in');

    // Test Analytics
    logEvent(analytics, 'test_event');
    console.log('Analytics event logged');

    return true;
  } catch (error) {
    console.error('Firebase connection test failed:', error);
    return false;
  }
}
