// TODO: Install date-fns package if date formatting is needed
// import { format, parseISO } from 'date-fns';

/**
 * Formats a date string into a specified format.
 * @param {string} dateString - The date string to format.
 * @param {string} formatString - The desired format (not used in this implementation).
 * @returns {string} The original date string.
 */
export const formatDate = (dateString, formatString = 'MM/dd/yyyy') => {
  // TODO: Implement date formatting using date-fns when the package is installed
  console.warn('Date formatting is not implemented. Install date-fns for full functionality.');
  return dateString;
};

/**
 * Renders HTML content safely.
 * @param {string} htmlString - The HTML string to render.
 * @returns {Object} An object with __html property for dangerouslySetInnerHTML.
 */
export const renderHTML = (htmlString) => {
  return { __html: htmlString };
};

/**
 * Formats a URL for display, optionally truncating it.
 * @param {string} url - The URL to format.
 * @param {number} maxLength - Maximum length before truncation (default: 50).
 * @returns {string} The formatted URL.
 */
export const formatURL = (url, maxLength = 50) => {
  if (url.length <= maxLength) return url;
  return url.substring(0, maxLength - 3) + '...';
};

/**
 * Truncates text to a specified length.
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - Maximum length before truncation (default: 100).
 * @returns {string} The truncated text.
 */
export const truncateText = (text, maxLength = 100) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength - 3) + '...';
};

/**
 * Applies a data manipulation rule to the given value.
 * @param {string} value - The value to manipulate.
 * @param {Object} rule - The manipulation rule object.
 * @returns {string|Object} The manipulated value or an object for rendering HTML.
 */
export const applyDataManipulationRule = (value, rule) => {
  if (!rule || !rule.type) return value;

  switch (rule.type) {
    case 'prefix':
      return `${rule.value}${value}`;
    case 'suffix':
      return `${value}${rule.value}`;
    case 'replace':
      return value.replace(new RegExp(rule.search, 'g'), rule.replace);
    case 'truncate':
      return truncateText(value, rule.maxLength);
    case 'dateFormat':
      return formatDate(value, rule.format);
    case 'filloutPopupEmbed':
      return value; // Return the original URL, it will be handled by the FilloutPopup component
    case 'render-html':
      return renderHTML(value);
    default:
      return value;
  }
};
