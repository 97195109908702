import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import "@fillout/react/style.css";
import { RoleProvider } from './contexts/RoleContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword';
import Dashboard from './components/Dashboard/Dashboard';
import AirtableConnectionsManager from './components/Admin/AirtableConnectionsManager';
import AirtableConnectionTester from './components/Admin/AirtableConnectionTester';
import UserGroupManager from './components/Admin/UserGroupManager';
import UserManager from './components/Admin/UserManager';
import PageManager from './components/Admin/PageManager';
import PageContent from './components/PageContent';
import PrivateRoute from './routes/PrivateRoute';
import AdminRoute from './routes/AdminRoute';
import Sidebar from './components/Layout/Sidebar';
import Home from './components/Home';
import TestPage from './components/TestPage';
import { useAuth } from './contexts/AuthContext';
import './App.css';

function App() {
  const { currentUser } = useAuth();

  return (
    <RoleProvider>
      <div className="App">
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <Sidebar />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: '100%', maxWidth: 'none' }}
          >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/airtable"
              element={
                <AdminRoute>
                  <AirtableConnectionsManager />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/airtable-test"
              element={
                <AdminRoute>
                  <AirtableConnectionTester />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/user-groups"
              element={
                <AdminRoute>
                  <UserGroupManager />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute>
                  <UserManager />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/pages"
              element={
                <AdminRoute>
                  <PageManager />
                </AdminRoute>
              }
            />
            <Route
              path="/page/:slug"
              element={
                <PrivateRoute>
                  <PageContent />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                currentUser ? (
                  <PageContent />
                ) : (
                  <Navigate to="/home" replace />
                )
              }
            />
          </Routes>
        </Box>
      </Box>
      </div>
    </RoleProvider>
  );
}

export default App;
