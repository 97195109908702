import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

function AddUserDialog({ open, onClose, onAddUser, newUserEmail, setNewUserEmail, newUserContactId, setNewUserContactId, newUserRole, setNewUserRole, newUserGroups, setNewUserGroups, userGroups }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Contact ID"
          type="number"
          fullWidth
          value={newUserContactId}
          onChange={(e) => setNewUserContactId(e.target.value)}
        />
        <TextField
          select
          margin="dense"
          label="Role"
          fullWidth
          value={newUserRole}
          onChange={(e) => setNewUserRole(e.target.value)}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="administrator">Administrator</MenuItem>
        </TextField>
        <FormGroup>
          {userGroups.map((group) => (
            <FormControlLabel
              key={group.id}
              control={
                <Checkbox
                  checked={newUserGroups.includes(group.id)}
                  onChange={() => {
                    setNewUserGroups(prev =>
                      prev.includes(group.id)
                        ? prev.filter(id => id !== group.id)
                        : [...prev, group.id]
                    );
                  }}
                />
              }
              label={group.name}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAddUser}>Add User</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUserDialog;
