import React, { useState, useEffect } from 'react';
import { Typography, Container, CircularProgress } from '@mui/material';
import { db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../constants/firebaseCollections';

function Home() {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomeContent = async () => {
      try {
        const homePageRef = doc(db, COLLECTIONS.PAGES, 'home');
        const homePageSnap = await getDoc(homePageRef);
        if (homePageSnap.exists()) {
          setContent(homePageSnap.data().content);
        } else {
          setContent('Welcome to the Student Portal');
        }
      } catch (error) {
        console.error('Error fetching home page content:', error);
        setContent('Welcome to the Student Portal');
      } finally {
        setLoading(false);
      }
    };

    fetchHomeContent();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Home
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  );
}

export default Home;
