import Airtable from 'airtable';
import { db } from './firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { COLLECTIONS } from '../constants/firebaseCollections';

const getGlobalPAT = async () => {
  const docRef = doc(db, 'globalSettings', 'airtableSettings');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().personalAccessToken;
  }
  throw new Error('Global Airtable Personal Access Token not found');
};

const getAirtableConnection = async (connectionName) => {
  const connectionsRef = doc(db, 'airtableConnections', 'connections');
  const docSnap = await getDoc(connectionsRef);
  if (docSnap.exists()) {
    const connections = docSnap.data().connections || [];
    const connection = connections.find(conn => conn.name === connectionName);
    if (!connection) {
      throw new Error(`Airtable connection '${connectionName}' not found in the connections list`);
    }
    return connection;
  } else {
    throw new Error('Airtable connections document not found');
  }
};

export const fetchTables = async (baseId) => {
  try {
    const globalPAT = await getGlobalPAT();
    
    // Fetch the schema of the base, which includes table information
    const response = await fetch(`https://api.airtable.com/v0/meta/bases/${baseId}/tables`, {
      headers: {
        'Authorization': `Bearer ${globalPAT}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const schema = await response.json();

    // Extract table information from the schema
    const tables = schema.tables.map(table => ({
      id: table.id,
      name: table.name
    }));

    return tables;
  } catch (error) {
    console.error('Error fetching tables from Airtable:', error);
    throw error;
  }
};

export const fetchFields = async (baseId, tableName) => {
  try {
    const globalPAT = await getGlobalPAT();
    
    // Fetch the schema of the base, which includes field information
    const response = await fetch(`https://api.airtable.com/v0/meta/bases/${baseId}/tables`, {
      headers: {
        'Authorization': `Bearer ${globalPAT}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const schema = await response.json();

    // Find the selected table and extract its fields
    const selectedTable = schema.tables.find(table => table.name === tableName);
    if (!selectedTable) {
      throw new Error(`Table ${tableName} not found in base ${baseId}`);
    }

    const fields = selectedTable.fields.map(field => ({
      id: field.id,
      name: field.name,
      type: field.type
    }));

    return fields;
  } catch (error) {
    console.error('Error fetching fields from Airtable:', error);
    throw error;
  }
};

const fetchManipulationRules = async () => {
  try {
    const rulesSnapshot = await getDocs(collection(db, COLLECTIONS.DATA_MANIPULATION_RULES));
    const rules = {};
    rulesSnapshot.forEach((doc) => {
      rules[doc.id] = doc.data();
    });
    return rules;
  } catch (error) {
    console.error('Error fetching manipulation rules:', error);
    throw error;
  }
};

export const fetchRecords = async (connectionName, user) => {
  try {
    console.log('Fetching records for connection:', connectionName);
    const globalPAT = await getGlobalPAT();
    console.log('Global PAT retrieved');
    const connection = await getAirtableConnection(connectionName);
    console.log('Connection details retrieved:', connection);

    const base = new Airtable({ apiKey: globalPAT }).base(connection.baseId);
    console.log('Airtable base initialized');
    
    let queryParams = {};

    if (connection.view) {
      queryParams.view = connection.view;
    }

    if (connection.fields) {
      const fieldNames = connection.fields.split(',').map(field => field.trim());
      queryParams.fields = fieldNames;
    }

    if (connection.filterByFormula) {
      // Replace user variables in the filter formula
      let filterFormula = connection.filterByFormula;
      if (user) {
        console.log('User object before replacement:', JSON.stringify(user, null, 2));
        
        // Fetch user profile from Firestore
        const userDocRef = doc(db, COLLECTIONS.STUDENT_PROFILES, user.uid);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          console.log('User profile data:', JSON.stringify(userData, null, 2));

          filterFormula = filterFormula.replace(/{user.email}/g, `'${userData.email || ''}'`);
          filterFormula = filterFormula.replace(/{user.firstName}/g, `'${userData.firstName || ''}'`);
          filterFormula = filterFormula.replace(/{user.lastName}/g, `'${userData.lastName || ''}'`);
          filterFormula = filterFormula.replace(/{user.secondaryEmail}/g, `'${userData.secondaryEmail || ''}'`);
          filterFormula = filterFormula.replace(/{user.contactId}/g, `'${userData.contactId || ''}'`);
        
          console.log('User properties used in replacement:',
            `email: ${userData.email || 'N/A'}`,
            `firstName: ${userData.firstName || 'N/A'}`,
            `lastName: ${userData.lastName || 'N/A'}`,
            `secondaryEmail: ${userData.secondaryEmail || 'N/A'}`,
            `contactId: ${userData.contactId || 'N/A'}`
          );
        } else {
          console.log('User profile not found in Firestore');
        }
      } else {
        console.log('User object is null or undefined');
      }
      console.log('Filter formula after replacement:', filterFormula);
      queryParams.filterByFormula = filterFormula;
      console.log('Applied filter formula:', filterFormula);
    }

    console.log('Query parameters:', queryParams);

    let query = base(connection.tableName).select(queryParams);
    console.log('Query created');

    const records = await query.all();
    console.log('Records fetched:', records.length);

    const orderedFields = connection.fields.split(',').map(field => field.trim());
    const manipulationRules = await fetchManipulationRules();
    
    const result = {
      records: records.map(record => {
        const orderedRecord = {};
        const manipulatedRecord = {};
        orderedFields.forEach(field => {
          const customLabel = connection.customLabels && connection.customLabels[field];
          const key = customLabel?.label || field;
          let value = record.fields[field];
          let manipulatedValue = value;

          // Apply manipulation rules
          if (connection.manipulationRules && connection.manipulationRules[field]) {
            const ruleId = connection.manipulationRules[field];
            const rule = manipulationRules[ruleId];
            if (rule) {
              switch (rule.type) {
                case 'uppercase':
                  manipulatedValue = value?.toUpperCase();
                  break;
                case 'lowercase':
                  manipulatedValue = value?.toLowerCase();
                  break;
                case 'prefix':
                  manipulatedValue = rule.value + (value || '');
                  break;
                case 'suffix':
                  manipulatedValue = (value || '') + rule.value;
                  break;
                case 'url-to-link':
                  manipulatedValue = value ? `<a href="${value}" target="_blank" rel="noopener noreferrer">${value}</a>` : '';
                  break;
                case 'date-format':
                  if (value && !isNaN(Date.parse(value))) {
                    const date = new Date(value);
                    switch (rule.value) {
                      case 'MM/DD/YYYY':
                        manipulatedValue = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                        break;
                      case 'MM/DD/YYYY HH:mm':
                        manipulatedValue = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
                        break;
                      case 'MM/DD/YYYY hh:mm A':
                        manipulatedValue = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
                        break;
                      case 'MMMM D, YYYY':
                        manipulatedValue = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                        break;
                      case 'MMMM D, YYYY hh:mm A':
                        manipulatedValue = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
                        break;
                      default:
                        manipulatedValue = date.toLocaleDateString();
                    }
                  }
                  break;
                case 'filloutPopupEmbed':
                  // For filloutPopupEmbed, we don't manipulate the value directly
                  // Instead, we'll handle this in the renderCellContent function in PageContent.js
                  break;
                case 'render-html':
                  // For render-html, we don't manipulate the value
                  // It will be handled in the renderCellContent function in PageContent.js
                  break;
                // Add more manipulation types as needed
                default:
                  console.warn(`Unknown manipulation rule type: ${rule.type}`);
              }
            }
          }

          orderedRecord[key] = value;
          manipulatedRecord[key] = manipulatedValue;
        });
        return { original: orderedRecord, manipulated: manipulatedRecord };
      }),
      displayFormat: connection.displayFormat || 'table',
      customLabels: connection.customLabels || {}
    };
    console.log('Records processed. Result:', JSON.stringify(result, null, 2));
    return result;
  } catch (error) {
    console.error('Error fetching records from Airtable:', error);
    console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
    if (error.error === 'INVALID_FILTER_BY_FORMULA') {
      console.error('Invalid filter formula');
      throw new Error(`Invalid filter formula: ${error.message}`);
    }
    throw error;
  }
};

export const fetchMultipleConnections = async (connectionNames, user) => {
  const results = {};
  for (const connectionName of connectionNames) {
    results[connectionName] = await fetchRecords(connectionName, user);
  }
  return results;
};

export const filterRecords = (records, filterFunction) => {
  return records.filter(filterFunction);
};

export const sortRecords = (records, sortField, ascending = true) => {
  return records.sort((a, b) => {
    if (a[sortField] < b[sortField]) return ascending ? -1 : 1;
    if (a[sortField] > b[sortField]) return ascending ? 1 : -1;
    return 0;
  });
};
