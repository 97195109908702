import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Typography, Container, Box } from '@mui/material';

function PrivateRoute({ children, requiredGroups = [] }) {
  const { currentUser, loading, userGroups } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/home" replace />;
  }

  if (requiredGroups.length > 0 && !requiredGroups.some(group => userGroups.includes(group))) {
    return (
      <Container>
        <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" component="h2" gutterBottom>
            You do not have permission to view this page.
          </Typography>
        </Box>
      </Container>
    );
  }

  return children;
}

export default PrivateRoute;
