import React, { useState, useEffect } from 'react';
import { FilloutPopupEmbed } from '@fillout/react';
import './FilloutPopup.css'; // Import the CSS file

const FilloutPopup = ({ formId, buttonText, domain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  console.log('FilloutPopup component called with:', { formId, buttonText });

  // Extract the actual form ID and record ID from the URL
  const urlParts = formId.split('/');
  const extractedFormId = urlParts[urlParts.length - 1].split('?')[0];
  const recordId = new URLSearchParams(formId.split('?')[1]).get('id');
  console.log('Extracted form ID:', extractedFormId, 'Record ID:', recordId);

  const handleOpen = () => {
    setIsOpen(true);
    setError(null);
    setRetryCount(0);
  };

  const handleClose = () => {
    setIsOpen(false);
    setError(null);
  };

  const handleError = (err) => {
    console.error('Fillout form error:', err);
    if (retryCount < 3) {
      setRetryCount(prevCount => prevCount + 1);
    } else {
      setError('We\'re experiencing technical difficulties. Please try again later or contact support if the problem persists.');
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (retryCount > 0 && retryCount <= 3) {
      const timer = setTimeout(() => {
        console.log(`Retrying to load form. Attempt ${retryCount}`);
        setIsOpen(false);
        setIsOpen(true);
      }, 2000 * retryCount); // Increased delay between retries
      return () => clearTimeout(timer);
    }
  }, [retryCount]);

  return (
    <>
      <button onClick={handleOpen} className="fillout-button">
        {buttonText}
      </button>

      {error && <div className="fillout-error">{error}</div>}

      {isOpen && (
        <FilloutPopupEmbed
          filloutId={extractedFormId}
          onClose={handleClose}
          onError={handleError}
          parameters={{ id: recordId }}
          options={{ timeout: 15000 }} // Increased timeout to 15 seconds
          domain={domain}
        />
      )}
    </>
  );
};

export default FilloutPopup;
