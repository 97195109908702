import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  DialogContentText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { db } from '../../services/firebase';
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { fetchTables, fetchFields } from '../../services/airtable';
import FilterFormulaBuilder from './FilterFormulaBuilder';

function AirtableConnectionsManager() {
  const [connections, setConnections] = useState([]);
  const [newConnection, setNewConnection] = useState({
    name: '',
    baseId: '',
    tableName: '',
    view: '',
    fields: [],
    customLabels: {},
    filterByFormula: '',
    displayFormat: 'table',
    manipulationRules: {},
  });
  const [editingConnection, setEditingConnection] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [globalPAT, setGlobalPAT] = useState('');
  const [tables, setTables] = useState([]);
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [manipulationRules, setManipulationRules] = useState([]);

  useEffect(() => {
    fetchConnections();
    fetchGlobalPAT();
    fetchManipulationRules();
  }, []);

  const fetchManipulationRules = async () => {
    try {
      const rulesRef = collection(db, 'dataManipulationRules');
      const rulesSnapshot = await getDocs(rulesRef);
      const rulesData = rulesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setManipulationRules(rulesData);
      console.log('Fetched manipulation rules:', rulesData);
    } catch (error) {
      console.error('Error fetching manipulation rules:', error);
    }
  };

  const fetchConnections = async () => {
    const connectionsRef = doc(db, 'airtableConnections', 'connections');
    const docSnap = await getDoc(connectionsRef);
    if (docSnap.exists()) {
      setConnections(docSnap.data().connections || []);
    } else {
      setConnections([]);
    }
  };

  const fetchGlobalPAT = async () => {
    const docRef = doc(db, 'globalSettings', 'airtableSettings');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setGlobalPAT(docSnap.data().personalAccessToken);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setNewConnection((prev) => ({ ...prev, [name]: value }));

    if (name === 'baseId' && value) {
      setLoading(true);
      try {
        const fetchedTables = await fetchTables(value);
        setTables(fetchedTables);
        setStep(2);
      } catch (error) {
        console.error('Error fetching tables:', error);
      } finally {
        setLoading(false);
      }
    }

    if (name === 'tableName' && value) {
      setLoading(true);
      try {
        const fetchedFields = await fetchFields(newConnection.baseId, value);
        setFields(fetchedFields);
        setStep(3);
      } catch (error) {
        console.error('Error fetching fields:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFieldChange = (fieldName) => {
    setNewConnection((prev) => {
      const newFields = prev.fields.includes(fieldName)
        ? prev.fields.filter((f) => f !== fieldName)
        : [...prev.fields, fieldName];
      return { ...prev, fields: newFields };
    });
  };

  const handleFieldOrderChange = (fieldName, direction) => {
    setNewConnection((prev) => {
      const fields = [...prev.fields];
      const index = fields.indexOf(fieldName);
      if (index === -1) return prev;

      const newIndex =
        direction === 'up'
          ? Math.max(0, index - 1)
          : Math.min(fields.length - 1, index + 1);
      fields.splice(index, 1);
      fields.splice(newIndex, 0, fieldName);

      return { ...prev, fields };
    });
  };

  const handleCustomLabelChange = (fieldName, customLabel, width) => {
    setNewConnection((prev) => ({
      ...prev,
      customLabels: {
        ...prev.customLabels,
        [fieldName]: {
          label: customLabel || null,
          width: width ? parseInt(width, 10) : null,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const connectionData = {
        ...newConnection,
        personalAccessToken: globalPAT,
        fields: newConnection.fields.join(','),
        manipulationRules: newConnection.manipulationRules,
        customLabels: Object.fromEntries(
          Object.entries(newConnection.customLabels)
            .filter(
              ([_, value]) => value.label !== null || value.width !== null
            )
            .map(([key, value]) => [
              key,
              {
                label: value.label || null,
                width: value.width ? parseInt(value.width, 10) : null,
              },
            ])
        ),
      };
      const connectionsRef = doc(db, 'airtableConnections', 'connections');
      const docSnap = await getDoc(connectionsRef);

      let updatedConnections;
      if (docSnap.exists()) {
        updatedConnections = docSnap.data().connections || [];
      } else {
        updatedConnections = [];
      }

      if (editingConnection) {
        const index = updatedConnections.findIndex(
          (conn) => conn.name === editingConnection.name
        );
        if (index !== -1) {
          updatedConnections[index] = connectionData;
        }
      } else {
        updatedConnections.push(connectionData);
      }

      await setDoc(connectionsRef, { connections: updatedConnections });

      setNewConnection({
        name: '',
        baseId: '',
        tableName: '',
        view: '',
        fields: [],
        customLabels: {},
        filterByFormula: '',
        manipulationRules: {},
      });
      setEditingConnection(null);
      setIsDialogOpen(false);
      setStep(1);
      fetchConnections();
    } catch (error) {
      console.error('Error adding/updating connection:', error);
    }
  };

  const handleDelete = (connection) => {
    setConnectionToDelete(connection);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    if (connectionToDelete) {
      try {
        const connectionsRef = doc(db, 'airtableConnections', 'connections');
        const docSnap = await getDoc(connectionsRef);
        if (docSnap.exists()) {
          let updatedConnections = docSnap
            .data()
            .connections.filter((conn) => conn.name !== connectionToDelete.name);
          await setDoc(connectionsRef, { connections: updatedConnections });
        }
        fetchConnections();
      } catch (error) {
        console.error('Error deleting connection:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setConnectionToDelete(null);
  };

  const handleEdit = async (connection) => {
    setEditingConnection(connection);
    setNewConnection({
      name: connection.name,
      baseId: connection.baseId,
      tableName: connection.tableName,
      view: connection.view || '',
      fields: connection.fields ? connection.fields.split(',') : [],
      customLabels: connection.customLabels || {},
      filterByFormula: connection.filterByFormula || '',
      displayFormat: connection.displayFormat || 'table',
      manipulationRules: connection.manipulationRules || {},
    });
    setIsDialogOpen(true);
    setStep(2);

    // Fetch tables and fields for editing
    try {
      const fetchedTables = await fetchTables(connection.baseId);
      setTables(fetchedTables);
      const fetchedFields = await fetchFields(
        connection.baseId,
        connection.tableName
      );
      setFields(fetchedFields);
      setStep(3);
    } catch (error) {
      console.error('Error fetching data for editing:', error);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setEditingConnection(null);
    setNewConnection({
      name: '',
      baseId: '',
      tableName: '',
      view: '',
      fields: [],
      filterByFormula: '',
    });
    setTables([]);
    setFields([]);
    setStep(1);
  };

  const handleManipulationRuleChange = (field, ruleId) => {
    setNewConnection((prev) => ({
      ...prev,
      manipulationRules: {
        ...prev.manipulationRules,
        [field]: ruleId,
      },
    }));
  };

  const [newRule, setNewRule] = useState({ name: '', type: '', value: '' });
  const [editingRule, setEditingRule] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [connectionToDelete, setConnectionToDelete] = useState(null);
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const [deleteRuleConfirmOpen, setDeleteRuleConfirmOpen] = useState(false);

  const handleAddRule = async () => {
    try {
      const rulesRef = collection(db, 'dataManipulationRules');
      await addDoc(rulesRef, newRule);
      setNewRule({ name: '', type: '', value: '' });
      fetchManipulationRules();
    } catch (error) {
      console.error('Error adding new rule:', error);
    }
  };

  const handleEditRule = (rule) => {
    setEditingRule(rule);
    setNewRule({ name: rule.name, type: rule.type, value: rule.value });
  };

  const handleUpdateRule = async () => {
    try {
      const ruleRef = doc(db, 'dataManipulationRules', editingRule.id);
      await updateDoc(ruleRef, newRule);
      setNewRule({ name: '', type: '', value: '' });
      setEditingRule(null);
      fetchManipulationRules();
    } catch (error) {
      console.error('Error updating rule:', error);
    }
  };

  const handleDeleteRule = (rule) => {
    setRuleToDelete(rule);
    setDeleteRuleConfirmOpen(true);
  };

  const confirmDeleteRule = async () => {
    if (ruleToDelete) {
      try {
        const ruleRef = doc(db, 'dataManipulationRules', ruleToDelete.id);
        await deleteDoc(ruleRef);
        fetchManipulationRules();
      } catch (error) {
        console.error('Error deleting rule:', error);
      }
    }
    setDeleteRuleConfirmOpen(false);
    setRuleToDelete(null);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Manage Airtable Connections
      </Typography>
      <Button variant="contained" onClick={() => setIsDialogOpen(true)}>
        Add New Connection
      </Button>
      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Data Manipulation Rules
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Rule Name"
          value={newRule.name}
          onChange={(e) => setNewRule({ ...newRule, name: e.target.value })}
          sx={{ mr: 1 }}
        />
        <FormControl sx={{ mr: 1, minWidth: 120 }}>
          <InputLabel>Rule Type</InputLabel>
          <Select
            value={newRule.type}
            onChange={(e) => setNewRule({ ...newRule, type: e.target.value })}
            label="Rule Type"
          >
            <MenuItem value="uppercase">Uppercase</MenuItem>
            <MenuItem value="lowercase">Lowercase</MenuItem>
            <MenuItem value="prefix">Prefix</MenuItem>
            <MenuItem value="suffix">Suffix</MenuItem>
            <MenuItem value="url-to-link">URL to Link</MenuItem>
            <MenuItem value="date-format">Date Format</MenuItem>
            <MenuItem value="filloutPopupEmbed">Fillout Popup Embed</MenuItem>
            <MenuItem value="render-html">Render HTML</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Rule Value"
          value={newRule.value}
          onChange={(e) => setNewRule({ ...newRule, value: e.target.value })}
          sx={{ mr: 1 }}
        />
        <Button
          variant="contained"
          onClick={editingRule ? handleUpdateRule : handleAddRule}
        >
          {editingRule ? 'Update Rule' : 'Add Rule'}
        </Button>
        {editingRule && (
          <Button
            variant="outlined"
            onClick={() => {
              setEditingRule(null);
              setNewRule({ name: '', type: '', value: '' });
            }}
            sx={{ ml: 1 }}
          >
            Cancel Edit
          </Button>
        )}
      </Box>
      <List>
        {manipulationRules.map((rule) => (
          <ListItem key={rule.id}>
            <ListItemText
              primary={rule.name}
              secondary={`Type: ${rule.type}, Value: ${rule.value || 'N/A'}`}
            />
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => handleEditRule(rule)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDeleteRule(rule)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <List>
        {connections.map((connection) => (
          <ListItem
            key={connection.name}
            secondaryAction={
              <>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEdit(connection)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(connection)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={connection.name}
              secondary={`Base ID: ${connection.baseId}, Table: ${connection.tableName}`}
            />
          </ListItem>
        ))}
      </List>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingConnection ? 'Edit Connection' : 'Add New Connection'}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Connection Name"
              name="name"
              value={newConnection.name}
              onChange={handleInputChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="baseId"
              label="Base ID"
              name="baseId"
              value={newConnection.baseId}
              onChange={handleInputChange}
            />
            {step >= 2 && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="table-select-label">Table Name</InputLabel>
                <Select
                  labelId="table-select-label"
                  id="tableName"
                  name="tableName"
                  value={newConnection.tableName}
                  onChange={handleInputChange}
                  label="Table Name"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {tables.map((table) => (
                    <MenuItem key={table.id} value={table.name}>
                      {table.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {step >= 3 && (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  id="view"
                  label="View (optional)"
                  name="view"
                  value={newConnection.view}
                  onChange={handleInputChange}
                />
                <Typography variant="subtitle1" gutterBottom>
                  Select and Order Fields:
                </Typography>
                <Box display="flex">
                  <Box flexGrow={1} mr={2}>
                    <FormGroup>
                      {fields.map((field) => (
                        <FormControlLabel
                          key={field.id}
                          control={
                            <Checkbox
                              checked={newConnection.fields.includes(
                                field.name
                              )}
                              onChange={() => handleFieldChange(field.name)}
                              name={field.name}
                            />
                          }
                          label={field.name}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                  <Box flexGrow={1}>
                    <Typography variant="subtitle2" gutterBottom>
                      Selected Fields Order:
                    </Typography>
                    <List>
                      {newConnection.fields.map((fieldName, index) => (
                        <ListItem key={index} dense>
                          <ListItemText
                            primary={`${index + 1}. ${fieldName}`}
                          />
                          <Box display="flex" alignItems="center">
                            <TextField
                              size="small"
                              placeholder="Custom Label (optional)"
                              value={
                                newConnection.customLabels[fieldName]?.label ||
                                ''
                              }
                              onChange={(e) =>
                                handleCustomLabelChange(
                                  fieldName,
                                  e.target.value || null,
                                  newConnection.customLabels[fieldName]?.width
                                )
                              }
                              sx={{ mr: 1 }}
                            />
                            <TextField
                              size="small"
                              placeholder="Width (px)"
                              type="number"
                              value={
                                newConnection.customLabels[fieldName]?.width ||
                                ''
                              }
                              onChange={(e) =>
                                handleCustomLabelChange(
                                  fieldName,
                                  newConnection.customLabels[fieldName]?.label,
                                  e.target.value || null
                                )
                              }
                              sx={{ width: '80px' }}
                            />
                          </Box>
                          <IconButton
                            onClick={() =>
                              handleFieldOrderChange(fieldName, 'up')
                            }
                            size="small"
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleFieldOrderChange(fieldName, 'down')
                            }
                            size="small"
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <FilterFormulaBuilder
                    initialFormula={newConnection.filterByFormula}
                    onFormulaChange={(formula) =>
                      setNewConnection((prev) => ({
                        ...prev,
                        filterByFormula: formula,
                      }))
                    }
                  />
                  {newConnection.filterByFormula && (
                    <>
                      <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        rows={4}
                        id="filterByFormula"
                        label="Filter By Formula"
                        name="filterByFormula"
                        value={newConnection.filterByFormula}
                        onChange={(e) =>
                          setNewConnection((prev) => ({
                            ...prev,
                            filterByFormula: e.target.value,
                          }))
                        }
                        helperText="This formula will filter records based on the current user's data and the conditions you've set. You can edit it directly here."
                      />
                      <Tooltip title="Clear Filter Formula">
                        <IconButton
                          sx={{ position: 'absolute', right: 0, top: 0 }}
                          onClick={() =>
                            setNewConnection((prev) => ({
                              ...prev,
                              filterByFormula: '',
                            }))
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="display-format-label">
                    Display Format
                  </InputLabel>
                  <Select
                    labelId="display-format-label"
                    id="displayFormat"
                    value={newConnection.displayFormat}
                    onChange={(e) =>
                      setNewConnection((prev) => ({
                        ...prev,
                        displayFormat: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="table">Table</MenuItem>
                    <MenuItem value="definition">Definition</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="subtitle1" gutterBottom>
                  Data Manipulation Rules:
                </Typography>
                {newConnection.fields.map((field, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="subtitle2">{field}</Typography>
                    <Select
                      fullWidth
                      value={newConnection.manipulationRules?.[field] || ''}
                      onChange={(e) =>
                        handleManipulationRuleChange(field, e.target.value)
                      }
                    >
                      <MenuItem value="">No Manipulation</MenuItem>
                      {manipulationRules.map((rule) => (
                        <MenuItem key={rule.id} value={rule.id}>
                          {rule.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ))}
                {manipulationRules.length === 0 && (
                  <Typography color="error">
                    No manipulation rules available. Please add some in the Data
                    Manipulation Rules section.
                  </Typography>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={(e) => {
              handleSubmit(e).then(() => handleDialogClose());
            }}
            disabled={
              loading || (step === 3 && newConnection.fields.length === 0)
            }
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : editingConnection ? (
              'Update'
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the connection "{connectionToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Rule Confirmation Dialog */}
      <Dialog
        open={deleteRuleConfirmOpen}
        onClose={() => setDeleteRuleConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete Rule</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the rule "{ruleToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteRuleConfirmOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteRule} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AirtableConnectionsManager;
