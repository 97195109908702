import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";

// This component suppresses the specific defaultProps warning
const WarningSupressor = ({ children }) => {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (args[0].includes('Warning: Connect(Droppable): Support for defaultProps')) {
      return;
    }
    originalConsoleError.apply(console, args);
  };

  useEffect(() => {
    return () => {
      console.error = originalConsoleError;
    };
  }, [originalConsoleError]);

  return children;
};

export const StrictModeDroppable = ({ children, droppableId = "DEFAULT_DROPPABLE_ID", type = "DEFAULT", direction = "vertical", ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return (
    <WarningSupressor>
      <Droppable droppableId={droppableId} type={type} direction={direction} {...props}>
        {children}
      </Droppable>
    </WarningSupressor>
  );
};
