import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getUserRole } from '../services/firebase';

const RoleContext = createContext();

export function useRole() {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error('useRole must be used within a RoleProvider');
  }
  return context;
}

export function RoleProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    async function fetchUserRole() {
      if (currentUser) {
        try {
          const role = await getUserRole(currentUser.uid);
          setUserRole(role);
        } catch (error) {
          console.error('Error fetching user role:', error);
          setUserRole('user'); // Default to 'user' if there's an error
        }
      } else {
        setUserRole(null);
      }
      setLoading(false);
    }

    fetchUserRole();
  }, [currentUser]);

  const value = {
    userRole,
    isAdministrator: userRole === 'administrator',
    loading
  };

  return (
    <RoleContext.Provider value={value}>
      {!loading && children}
    </RoleContext.Provider>
  );
}
