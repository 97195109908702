import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Typography, Container, Box, List, ListItem, ListItemText, Checkbox, FormGroup, FormControlLabel, Alert, TextField, CircularProgress, MenuItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { db, createNewUser, updateUserEmail, updateUserContactId, updateUserAdminStatus, deleteUser, resendVerificationEmail } from '../../services/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { COLLECTIONS } from '../../constants/firebaseCollections';
import { useAuth } from '../../contexts/AuthContext';
import { useRole } from '../../contexts/RoleContext';
import DeleteUserConfirmation from './DeleteUserConfirmation';
import AddUserDialog from './AddUserDialog';

function UserManager() {
  const containerRef = useRef(null);
  const { currentUser } = useAuth();
  const { isAdministrator } = useRole();
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [contactId, setContactId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserContactId, setNewUserContactId] = useState('');
  const [newUserRole, setNewUserRole] = useState('user');
  const [newUserGroups, setNewUserGroups] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [emailVerificationStatus, setEmailVerificationStatus] = useState({});

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (container) {
        container.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  const checkAdminStatus = useCallback(() => {
    console.log("Checking admin status...");
    if (currentUser) {
      console.log("Current user:", currentUser.uid);
      console.log("Current user email:", currentUser.email);
      console.log("Is administrator:", isAdministrator);
      if (isAdministrator) {
        console.log("User is admin");
      } else {
        console.log("User is not admin");
        setError("You do not have permission to access this page.");
      }
    } else {
      console.log("No user logged in");
      setError("No user logged in. Please log in and try again.");
    }
    setLoading(false);
  }, [currentUser, isAdministrator]);

  const fetchData = useCallback(async () => {
    if (isAdministrator) {
      try {
        const [usersSnapshot, groupsSnapshot, authUsersSnapshot] = await Promise.all([
          getDocs(collection(db, COLLECTIONS.STUDENT_PROFILES)),
          getDocs(collection(db, COLLECTIONS.USER_GROUPS)),
          getDocs(collection(db, 'users'))
        ]);

        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const authData = {};
        authUsersSnapshot.forEach(doc => {
          authData[doc.id] = doc.data();
        });

        const combinedUsers = await Promise.all(usersData.map(async user => {
          const authEmail = authData[user.id]?.email;
          let email = user.email || authEmail || 'Email not found';
          
          if (authEmail && (!user.email || user.email !== authEmail)) {
            await updateDoc(doc(db, COLLECTIONS.STUDENT_PROFILES, user.id), { email: authEmail });
            email = authEmail;
          }
          
          return { ...user, email: email };
        }));
        
        setUsers(combinedUsers);
        setUserGroups(groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again.");
      }
    }
  }, [isAdministrator]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        checkAdminStatus();
        if (isAdministrator) {
          await fetchData();
        } else {
          setError("You do not have permission to access this page.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please check your permissions and try again.");
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [currentUser, isAdministrator, checkAdminStatus, fetchData]);

  const filteredUsers = users.filter(user => 
    user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSelectedGroups(user.groups || []);
    setContactId(user.contactId || '');
    setUserRole(user.role || 'user');
  };

  const handleRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const handleGroupToggle = (groupId) => {
    setSelectedGroups(prev => 
      prev.includes(groupId) ? prev.filter(id => id !== groupId) : [...prev, groupId]
    );
  };

  const handleUpdateUserGroups = async () => {
    if (selectedUser) {
      try {
        console.log("Updating user information for:", selectedUser.id);
        console.log("New groups:", selectedGroups);
        console.log("New Contact ID:", contactId);
        console.log("New Role:", userRole);
        
        // Update user information
        const userRef = doc(db, COLLECTIONS.STUDENT_PROFILES, selectedUser.id);
        await updateDoc(userRef, { 
          groups: selectedGroups,
          contactId: contactId,
          role: userRole
        });
        
        console.log("User information updated successfully");
        fetchData(); // Refresh the user list
        setSelectedUser(null);
        setSelectedGroups([]);
        setContactId('');
        setUserRole('');
        setError(''); // Clear any previous errors
      } catch (error) {
        console.error("Error updating user information:", error);
        // Log more details about the error
        if (error.code) {
          console.error("Error code:", error.code);
        }
        if (error.details) {
          console.error("Error details:", error.details);
        }
        setError(`Failed to update user information: ${error.message}. Please check your permissions and try again.`);
      }
    }
  };

  const handleAddNewUser = async () => {
    try {
      if (!newUserEmail || !newUserContactId) {
        throw new Error('Email and Contact ID are required');
      }
      
      const userId = await createNewUser(newUserEmail, newUserContactId, newUserRole, newUserGroups);
      
      // Update user's email in profile
      await updateUserEmail(userId, newUserEmail);
      
      // Update user's contact ID
      await updateUserContactId(userId, newUserContactId);
      
      // Update user's admin status if role is administrator
      if (newUserRole === 'administrator') {
        await updateUserAdminStatus(userId, true);
      }
      
      // Close the dialog and reset the form
      setShowAddUserDialog(false);
      setNewUserEmail('');
      setNewUserContactId('');
      setNewUserRole('user');
      setNewUserGroups([]);
      
      // Refresh the user list
      fetchData();
      
      // Show success message
      setError('New user created successfully. They will receive an email to verify their account.');
    } catch (error) {
      console.error("Error adding new user:", error);
      setError(`Failed to add new user: ${error.message}`);
    }
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      try {
        await deleteUser(userToDelete.id);
        setUsers(users.filter(user => user.id !== userToDelete.id));
        setShowDeleteConfirmation(false);
        setUserToDelete(null);
        setSelectedUser(null);
        setError('User deleted successfully');
      } catch (error) {
        console.error("Error deleting user:", error);
        setError(`Failed to delete user: ${error.message}`);
      }
    }
  };

  const handleResendVerificationEmail = async (userId) => {
    try {
      const userEmail = users.find(user => user.id === userId)?.email;
      if (!userEmail) {
        throw new Error('User email not found');
      }
      await resendVerificationEmail(userEmail);
      setEmailVerificationStatus(prev => ({ ...prev, [userId]: 'Verification email sent successfully' }));
      setTimeout(() => {
        setEmailVerificationStatus(prev => ({ ...prev, [userId]: '' }));
      }, 5000);
    } catch (error) {
      console.error("Error resending verification email:", error);
      setEmailVerificationStatus(prev => ({ ...prev, [userId]: `Failed to resend: ${error.message}` }));
    }
  };

  return (
    <Container ref={containerRef}>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Panel
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      ) : isAdministrator ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <TextField
              label="Search Users"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <Button variant="contained" color="primary" onClick={() => setShowAddUserDialog(true)}>
              Add New User
            </Button>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <List sx={{ width: '50%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {filteredUsers.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleUserSelect(user)}
                  selected={selectedUser && selectedUser.id === user.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={(e) => {
                      e.stopPropagation();
                      setUserToDelete(user);
                      setShowDeleteConfirmation(true);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={user.email || 'Email not available'} />
                </ListItem>
              ))}
            </List>
            {selectedUser && (
              <Box sx={{ width: '50%', ml: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Edit User: {selectedUser.email}
                </Typography>
                <TextField
                  label="Email"
                  variant="outlined"
                  value={selectedUser.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Contact ID"
                  variant="outlined"
                  value={contactId}
                  onChange={(e) => setContactId(e.target.value)}
                  type="number"
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  select
                  label="Role"
                  value={userRole}
                  onChange={handleRoleChange}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="administrator">Administrator</MenuItem>
                </TextField>
                <Typography variant="subtitle1" gutterBottom>
                  Assign Groups
                </Typography>
                <FormGroup>
                  {userGroups.map((group) => (
                    <FormControlLabel
                      key={group.id}
                      control={
                        <Checkbox
                          checked={selectedGroups.includes(group.id)}
                          onChange={() => handleGroupToggle(group.id)}
                        />
                      }
                      label={group.name}
                    />
                  ))}
                </FormGroup>
                <Button
                  variant="contained"
                  onClick={handleUpdateUserGroups}
                  sx={{ mt: 2, mr: 2 }}
                >
                  Update User Groups
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleResendVerificationEmail(selectedUser.id)}
                  sx={{ mt: 2 }}
                >
                  Resend Verification Email
                </Button>
                {emailVerificationStatus[selectedUser.id] && (
                  <Typography color="primary" sx={{ mt: 2 }}>
                    {emailVerificationStatus[selectedUser.id]}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">You do not have permission to access this page. If you believe this is an error, please contact the administrator.</Alert>
      )}
      <AddUserDialog
        open={showAddUserDialog}
        onClose={() => setShowAddUserDialog(false)}
        onAddUser={handleAddNewUser}
        newUserEmail={newUserEmail}
        setNewUserEmail={setNewUserEmail}
        newUserContactId={newUserContactId}
        setNewUserContactId={setNewUserContactId}
        newUserRole={newUserRole}
        setNewUserRole={setNewUserRole}
        newUserGroups={newUserGroups}
        setNewUserGroups={setNewUserGroups}
        userGroups={userGroups}
      />
      <DeleteUserConfirmation
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDeleteUser}
        userName={userToDelete?.email || ''}
      />
    </Container>
  );
}

export default UserManager;
