import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Typography } from '@mui/material';
import { db } from '../services/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { COLLECTIONS } from '../constants/firebaseCollections';
import { fetchMultipleConnections } from '../services/airtable';
import { useAuth } from '../contexts/AuthContext';
import FilloutPopup from './FilloutPopup';
import { applyDataManipulationRule } from '../utils/dataManipulation';

function PageContent() {
  const [manipulationRules, setManipulationRules] = useState([]);
  const { slug } = useParams();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPage = async () => {
      setLoading(true);
      try {
        let pageSlug = slug || location.pathname.slice(1);
        if (!pageSlug) pageSlug = 'home';
        
        const q = query(collection(db, COLLECTIONS.PAGES), where("slug", "==", pageSlug));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const pageData = querySnapshot.docs[0].data();
          setPage(pageData);
          
          // Extract all Airtable connection names from shortcodes
          const connectionNames = extractConnectionNames(pageData.content);
          
          if (connectionNames.length > 0) {
            console.log('Fetching Airtable data for connections:', connectionNames);
            const airtableData = await fetchMultipleConnections(connectionNames, currentUser);
            console.log('Fetched Airtable data:', airtableData);
            setPage(prev => ({ ...prev, airtableData }));
          }

          // Fetch manipulation rules
          await fetchManipulationRules();
        } else {
          // If page not found, fetch the 404 page
          const notFoundQuery = query(collection(db, COLLECTIONS.PAGES), where("slug", "==", "404"));
          const notFoundSnapshot = await getDocs(notFoundQuery);
          
          if (!notFoundSnapshot.empty) {
            const notFoundPageData = notFoundSnapshot.docs[0].data();
            setPage(notFoundPageData);
          } else {
            setError('404 Page not found');
          }
        }
      } catch (err) {
        console.error('Error fetching page:', err);
        setError('Failed to load page. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [slug, location.pathname, currentUser]);

  const fetchManipulationRules = async () => {
    try {
      const rulesRef = collection(db, 'dataManipulationRules');
      const rulesSnapshot = await getDocs(rulesRef);
      const rulesData = rulesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setManipulationRules(rulesData);
      console.log('Fetched manipulation rules:', rulesData);
      console.log('Mark Your Attendance Popup rule:', rulesData.find(r => r.ruleName === 'Mark Your Attendance Popup'));
    } catch (error) {
      console.error('Error fetching manipulation rules:', error);
    }
  };

  const extractConnectionNames = (content) => {
    const regex = /\[airtable connection="([^"]+)"\]/g;
    const matches = content.matchAll(regex);
    return [...new Set([...matches].map(match => match[1]))];
  };

  const renderAirtableData = (data, displayFormat) => {
    console.log('Rendering Airtable data:', data);
    console.log('Display format:', displayFormat);

    if (!data || !data.records || data.records.length === 0) {
      console.log('No data to render');
      return null;
    }
    
    const headers = Object.keys(data.records[0].original);
    console.log('Headers:', headers);
    
    if (displayFormat === 'definition' && data.records.length === 1) {
      return (
        <Box sx={{ mt: 2 }}>
          {headers.map((header, index) => (
            <Box key={index} sx={{ mb: 1 }}>
              <Typography variant="subtitle2" component="span" sx={{ fontWeight: 'bold' }}>
                {data.customLabels[header]?.label || header}:
              </Typography>
              <Typography component="span" sx={{ ml: 1 }}>
                {renderCellContent(data.records[0].manipulated[header] || data.records[0].original[header], header)}
              </Typography>
            </Box>
          ))}
        </Box>
      );
    } else {
      return (
        <div className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell 
                    key={index} 
                    sx={{ 
                      width: data.customLabels[header]?.width ? `${data.customLabels[header].width}px` : 'auto',
                      minWidth: data.customLabels[header]?.width ? `${data.customLabels[header].width}px` : 'auto'
                    }}
                  >
                    {data.customLabels[header]?.label || header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.records.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {headers.map((header, cellIndex) => (
                    <TableCell 
                      key={cellIndex}
                      sx={{ 
                        width: data.customLabels[header]?.width ? `${data.customLabels[header].width}px` : 'auto',
                        minWidth: data.customLabels[header]?.width ? `${data.customLabels[header].width}px` : 'auto'
                      }}
                    >
                      {renderCellContent(row.manipulated[header] || row.original[header], header)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      );
    }
  };

  const renderCellContent = (content, header) => {
    console.log('Rendering cell content:', { header, content });
    console.log('All manipulation rules:', manipulationRules);

    // Check for Fillout Link
    if (content && typeof content === 'string' && content.startsWith('https://forms.sfcp.org/')) {
      console.log('Fillout Link detected');
      
      // Look for the 'Mark Your Attendance Popup' rule
      const rule = manipulationRules.find(r => r.name === 'Mark Your Attendance Popup' && r.type === 'filloutPopupEmbed');

      console.log('Found rule:', rule);

      if (rule) {
        console.log('Using filloutPopupEmbed rule');
        const buttonText = rule.value || "Open Form";
        console.log('Button text:', buttonText);
        return <FilloutPopup formId={content} buttonText={buttonText} domain="forms.sfcp.org" />;
      } else {
        console.log('No filloutPopupEmbed rule found, using default');
        return <FilloutPopup formId={content} buttonText="Open Form" domain="forms.sfcp.org" />;
      }
    }

    // Apply data manipulation rule
    const rule = manipulationRules.find(r => r.name === header);
    const manipulatedContent = applyDataManipulationRule(content, rule);

    // Check if the content is HTML (either as a string or an array)
    if (typeof content === 'string' && content.trim().startsWith('<') && content.trim().endsWith('>')) {
      console.log('Rendering HTML content (string)');
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    } else if (Array.isArray(content) && content.length > 0 && typeof content[0] === 'string' && content[0].trim().startsWith('<') && content[0].trim().endsWith('>')) {
      console.log('Rendering HTML content (array)');
      return <div dangerouslySetInnerHTML={{ __html: content[0] }} />;
    }

    // For non-HTML content
    console.log('Rendering non-HTML content');
    return <span>{manipulatedContent}</span>;
  };

  const renderContent = () => {
    if (!page || !page.content) return null;

    console.log('Page content:', page.content);
    console.log('Airtable data:', page.airtableData);

    const parts = page.content.split(/(\[airtable connection="[^"]+"\]|\[current_user_email\]|\[fillout form="[^"]+"\])/);
    return parts.map((part, index) => {
      if (part.startsWith('[airtable connection="')) {
        const connectionName = part.match(/connection="([^"]+)"/)[1];
        console.log('Rendering connection:', connectionName);
        const connectionData = page.airtableData?.[connectionName];
        console.log('Connection data:', connectionData);
        return connectionData ? (
          <React.Fragment key={index}>
            {renderAirtableData(connectionData, connectionData.displayFormat)}
          </React.Fragment>
        ) : null;
      } else if (part === '[current_user_email]') {
        return <span key={index}>{currentUser?.email || 'Not logged in'}</span>;
      } else if (part.startsWith('[fillout form="')) {
        const formId = part.match(/form="([^"]+)"/)[1];
        const rule = manipulationRules.find(r => r.ruleName === formId);
        const buttonText = rule ? rule.ruleValue : "Open Form";
        return <FilloutPopup key={index} formId={formId} buttonText={buttonText} domain="forms.sfcp.org" />;
      }
      return <div key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!page) return null;

  return (
    <Box sx={{ textAlign: 'left', maxWidth: 'none', width: '100%', overflowX: 'auto' }}>
      {renderContent()}
    </Box>
  );
}

export default PageContent;
